import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import {BsLinkedin,BsInstagram,BsFacebook }  from "react-icons/bs";
import { useTranslation } from 'react-i18next';  
import * as Constants from '../constants'
import NavDropdown from 'react-bootstrap/NavDropdown';

function Menu() {
  
  const { t, i18n } = useTranslation();  
  const changeLanguage = lng => {  
    i18n.changeLanguage(lng);  
  };  
  return (
    
  
<Navbar id="ftco-navbar"   sticky="top"  className="ftco-navbar-light"  expand="lg" variant="dark" bg="dark" >
  
      <Container className="container d-flex align-items-center">
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
        <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
             <Nav.Link style={{paddingRight:"20px"}} className=" py-3 pr-3 pl-0" href="#home">{t("Home")}</Nav.Link>
             <Nav.Link style={{paddingRight:"20px"}}  className=" py-3 pr-3"  href="#services">{t("Services")}</Nav.Link>
             <Nav.Link style={{paddingRight:"20px"}}  className=" py-3 pr-3"  href="#values">{t("Values")}</Nav.Link>
             <Nav.Link style={{paddingRight:"20px"}}  className=" py-3 pr-3"  href="#projects">{t("Projects")}</Nav.Link>
             <Nav.Link style={{paddingRight:"20px"}}  className=" py-3 pr-3"  href="#contact">{t("Contact")}</Nav.Link>
          </Nav>
           <div className="d-flex menu-social" style={{paddingRight:"40px"}}>
            {/* <a  title={t("followuson")+ " LinkedIn"} href={Constants.LINKEDIN_LINK} target="_blank"><BsLinkedin size={20} style={{marginRight:"20px"}}/></a>
            <a title={t("followuson")+ " Instagram"}  href={Constants.INSTAGRAM_LINK} target="_blank"><BsInstagram  size={20} style={{marginRight:"20px"}}/></a>
            <a title={t("followuson")+ " Facebook"}  href={Constants.FACEBOOK_LINK} target="_blank"><BsFacebook   size={20}/></a> */}
             { i18n.language==='fr'?<a className="mr-2" color="success" href="#" onClick={() => changeLanguage('en')}>En</a> :<a color="success" href="#"  onClick={() => changeLanguage('fr')}>Fr</a> }          
              
        </div> 
        </Navbar.Collapse>
        
      </Container>
      </Navbar>
      
  );
}

export default Menu;
