
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import serviceImage1 from "../images/overview.jpg"
import { useTranslation } from 'react-i18next';  

function Overview() {
  const { t, i18n } = useTranslation(); 
  return (
    <Container id="home" style={{paddingTop:"5em",paddingBottom:"10px"}}>
    <Row>   
      <Col md={7}  >
        <h2 class="mb-4">{t("Overview")}</h2>  
        <p style={{textAlign:"justify",paddingRight:"50px"}}>
        {t("Overviewtext1")}
        </p>  
        <p style={{textAlign:"justify",paddingRight:"50px"}}>
        {t("Overviewtext2")}
        </p>  
        <p style={{textAlign:"justify",paddingRight:"50px"}}>
        {t("Overviewtext3")}
        </p>  
     </Col>
    
      
    <Col md={5} className=" wrap-about align-items-stretch mb-5">
    <div class="wrap-about-border"  style={{border: "none", padding: "0"}}>
      <div class="img"  style={{height:"420px",backgroundColor:"#1b9ce3", backgroundImage: `url(${serviceImage1})`}}></div>
      </div>
      </Col>
    </Row>
  </Container>
      
  );
}

export default Overview;
