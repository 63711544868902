
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import {BiBullseye}  from "react-icons/bi";
import {GiTeamIdea}  from "react-icons/gi";
import {MdOutlineVisibility,MdVerified}  from "react-icons/md";
import {FaHandshake}  from "react-icons/fa";
import {AiOutlineRocket}  from "react-icons/ai";
import Container from 'react-bootstrap/Container'
import { useTranslation } from 'react-i18next'; 


function Values() {
  const { t, i18n } = useTranslation(); 
  return (
    <Container id="values" className="py-5">      
      <Row className="justify-content-center mb-5 pb-2">
        <Col md={8} className="text-center heading-section ">
          <h2 class="mb-4">{t("Our Values")}</h2>
         <p>{t("values-brief")}</p>
        </Col>
      </Row>
      <Row className="no-gutters" style={{paddingBottom:"34px"}}>
        <Col lg={4} className="d-flex">
          <div class="services-2 noborder-left text-center">
               <div class="text media-body"> 
                <div class="icon mt-2 d-flex justify-content-center align-items-center">
                  <span><MdVerified  size={50}/></span>
                </div>   
                  <h3>{t("Integrity")}</h3>
                  <p>{t("integrity-text")}</p>
              </div>
          </div>
        </Col>
        <Col lg={4} className="d-flex">
          <div class="services-2 text-center ">
              <div class="text media-body">
                <div class="icon mt-2 d-flex justify-content-center align-items-center">
                  <span><GiTeamIdea  size={50}/></span>
                </div>
                <h3>{t("Team Work")}</h3>
               <p>{t("team-text")}</p>
              </div>
          </div>
        </Col>
        <Col lg={4} className="d-flex">
          <div class="services-2 text-center ">
            <div class="text media-body">
               <div class="icon mt-2 d-flex justify-content-center align-items-center">
                <span><AiOutlineRocket  size={50}/></span>
              </div>
            <h3>{t("High Quality")}</h3>
            <p>{t("hq-text")}</p>
            </div>
          </div>
        </Col>
        <Col lg={4} className="d-flex">
          <div class="services-2 noborder-left noborder-bottom text-center ">
            <div class="text media-body">
               <div class="icon mt-2 d-flex justify-content-center align-items-center">
                <span><MdOutlineVisibility  size={50}/></span>
              </div>
              <h3>{t("Transparency")}</h3>
               <p>{t("Transparency-text")}</p>
            </div>
          </div>
        </Col>
        <Col lg={4} className="d-flex">
          <div class="services-2 text-center noborder-bottom ">
            <div class="text media-body">
              <div class="icon mt-2 d-flex justify-content-center align-items-center">
                <span><FaHandshake  size={50}/></span>
              </div>
             <h3>{t("Partnership")}</h3>
              <p>{t("Partnership-text")}</p>
            </div>
          </div>
        </Col>
        <Col lg={4} className="d-flex">
          <div class="services-2 text-center noborder-bottom ">
            <div class="icon mt-2 d-flex justify-content-center align-items-center">
            </div>
            <div class="text media-body">
                  <div class="icon mt-2 d-flex justify-content-center align-items-center">
                    <span><BiBullseye  size={50}/></span>
                  </div>
                  <h3>{t("Expertise")}</h3>
                  <p>{t("expertise-text")}</p>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
      
  );
}

export default Values;
