
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import firstImage from "../images/consulting.webp"
import secondImage from "../images/tech-support3jpg.jpg"
import thirdImage from "../images/consultancy.png"
import fourthImage from "../images/training2.jpg"
import {FaRegHandshake}  from "react-icons/fa";
import {GiTeacher,GiTeamUpgrade}  from "react-icons/gi";
import {MdOutlineEngineering}  from "react-icons/md";
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { useTranslation } from 'react-i18next';  



function Services() {
  const { t, i18n } = useTranslation(); 
  return (
    
    <Container id="services" style={{paddingTop:"80px"}}>
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
    <Row>
      
    
    <Col md={7} style={{paddingRight:"50px"}}>
    <h2 class="mb-4">{t("Our Services")}</h2>  
    <p>{t("servicequote")}</p>  
    <Row className="mt-5">
      <Col lg={6} style={{paddingLeft:0}}>
      <Nav variant="none" className="flex-column">
            <Nav.Item>
              <Nav.Link eventKey="first">
          
      <div class="services  text-center">
        <div class="text media-body">
          
        <FaRegHandshake  size={50}/>
        <h3>{t("Consultancy")}</h3>
        <p>{t("consultancy-brief")}</p>
        </div>
      </div>
      </Nav.Link>
      </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second">
      <div class="services  text-center">
        <div class="text media-body">
        <GiTeamUpgrade  size={50}/>
        <h3>{t("capacity")}</h3>
        <p>{t("capacity-brief")}</p>
        </div>
      </div></Nav.Link>
      </Nav.Item>
      </Nav>
      </Col>
      <Col lg={6} >
        
      <Nav variant="none" className="flex-column">
      <Nav.Item>
              <Nav.Link eventKey="third">
      <div class="services  text-center">
        <div class="text media-body">
        <MdOutlineEngineering  size={50}/>
        <h3>{t("execution")}</h3>
        <p>{t("exec-brief")}</p>
        </div>
      </div>
      </Nav.Link>
      </Nav.Item>
      <Nav.Item>
              <Nav.Link eventKey="fourth">
      <div class="services  text-center">
        <div class="text media-body">
        <GiTeacher  size={50}/>
        <h3>{t("Training")}</h3>
        <p>{t("Training-brief")}</p>
        </div>
      </div>
      
      </Nav.Link>
      </Nav.Item>
      </Nav>
      </Col>
      
    </Row>
    </Col>
    
      

      
    <Col md={5} className=" wrap-about align-items-stretch mb-5" style={{paddingBottom:"35px"}}>
    <Tab.Content style={{textAlign:"justify"}}>
            <Tab.Pane eventKey="first">
              
    <div class="wrap-about-border">
      <div class="img" style={{ backgroundImage: `url(${firstImage})`}}></div>
      <div class="text">
      <h3>{t("Consultancy")}</h3>
      <p>{t("consultancy-desc")}</p>

      <p><a href="#contact" class="btn btn-primary py-3 px-4">{t("contactus")}</a></p>
      </div>
      </div>
            </Tab.Pane>
            <Tab.Pane eventKey="second">
              
              <div class="wrap-about-border">
                <div class="img"  style={{ backgroundImage: `url(${thirdImage})`}} ></div>
                <div class="text">
                <h3>{t("capacity-title")}</h3>
                <p>{t("capacity-desc")}</p>

                <p><a href="#contact" class="btn btn-primary py-3 px-4">{t("contactus")}</a></p>
                </div>
                </div>
            </Tab.Pane>
            <Tab.Pane eventKey="third">
              
              <div class="wrap-about-border">
                <div class="img" style={{ backgroundImage: `url(${secondImage})`}}></div>
                <div class="text">
                <h3>{t("exec-title")}</h3>
                <p>{t("exec-desc")}</p>
                <p><a href="#contact" class="btn btn-primary py-3 px-4">{t("contactus")}</a></p>
                </div>
                </div>
            </Tab.Pane>
            <Tab.Pane eventKey="fourth">            
              <div class="wrap-about-border">
                <div class="img" style={{ backgroundImage: `url(${fourthImage})`}}></div>
                <div class="text">
                <h3>{t("Training-title")}</h3>
                <p>{t("Training-desc")}</p>
                <p><a href="#contact" class="btn btn-primary py-3 px-4">{t("contactus")}</a></p>
                </div>
                </div>
            </Tab.Pane>
          </Tab.Content>
      </Col>
    </Row>
    </Tab.Container>
  </Container>
      
  );
}

export default Services;
