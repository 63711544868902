
import 'bootstrap/dist/css/bootstrap.min.css';

import './style.css'
import './App.css';
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import serviceImage1 from "./images/consulting.jpg"
import background from "./images/bg_3.jpg.webp"
import background2 from "./images/background2.jpg"
import Services from './components/services'
import Values from './components/values'
import Info from './components/info'
import Menu from './components/menu'
import Overview from './components/overview'
import TopCarousel from './components/topCarousel'
import Projects from './components/projects'
import Contactus from './components/contactus'
import Fade from 'react-reveal/Fade';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next'; 

function App() {  
  const { t, i18n } = useTranslation(); 
  useEffect(() => {
    if(window.location.pathname!="/")
     document.getElementById(window.location.pathname.replaceAll("/","")).scrollIntoView();
  });
  return (
    <>
    <Info/>
    <Menu/>
    <Fade bottom>
    <TopCarousel/>
    <Overview/>
    <Container className="ftco-intro img  py-1" fluid style={{backgroundColor:"#1b9ce3", backgroundImage: `url(${background2})`}} >
      <Row md={10} className="text-center heading-section heading-section-white m-4 ">
      <h2 class="mb-0">{t("moto1")}</h2>
      </Row>
    </Container>
    <Services/>
    <Container className="ftco-intro img   py-1" fluid style={{marginBottom:"15px",backgroundColor:"#1b9ce3", backgroundImage: `url(${background2})`}} >
      <Row md={10} className="text-center heading-section heading-section-white m-4">
      <h2 class="mb-0">{t("moto2")}</h2>
      </Row>
    </Container>
    <Values/>
    <Container className="ftco-intro img   py-1" fluid style={{backgroundColor:"#1b9ce3", backgroundImage: `url(${background2})`}} >
      <Row md={10} className="text-center heading-section heading-section-white m-4">
      <h2 class="mb-0">{t("moto3")}</h2>
      </Row>
    </Container>
    <Projects/>
    <Contactus/>
    </Fade>
    </>
  );
}

export default App;
