import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import i18n from './i18n';  
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyC0tTXqrpNIxnJjc8Kll3Mht0_oSgoFcDo",
  authDomain: "platform0-5ed9a.firebaseapp.com",
  projectId: "platform0-5ed9a",
  storageBucket: "platform0-5ed9a.appspot.com",
  messagingSenderId: "664262752310",
  appId: "1:664262752310:web:067268a7d69d2d7ca38097",
  measurementId: "G-5XP8BTB8TV"
};
console.log = () => {}
console.error = () => {}
console.debug = () => {}
// Initialize Firebase

if (window.location.protocol == 'http:' && window.location.host.indexOf("platform0.io")>-1) {
      
  console.log("you are accessing us via "
      + "an insecure protocol (HTTP). "
      + "Redirecting you to HTTPS.");
        
  window.location.href =
      window.location.href.replace(
              'http:', 'https:');
      }
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
