
import Container from 'react-bootstrap/Container'
import dev2 from "../images/dev2.png"
import carous2 from "../images/carous2.png"
import ideacloud from "../images/ideacloud.jpg"
import Carousel from 'react-bootstrap/Carousel';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next'; 

function TopCarousel() {  
  const { t, i18n } = useTranslation(); 
  return (
    <Container fluid className="owl-carousel home-slider px-0">
      <Carousel interval={5000} fade controls={false}>
            <Carousel.Item>
              <div class="slider-item"  style={{ backgroundImage: `url(${ideacloud})`}}>
                <div class="overlay"></div>
                <Container>
                  <Row className="no-gutters slider-text align-items-center justify-content-start">
                    <Col md={7} >
                      <span class="subheading">{t("You AWS Partner")}</span>
                      <h1 class="mb-4">{t("A key to the cloud is only a click away")}</h1>
                      <p><a href="#projects" class="btn btn-primary px-4 py-3 mt-3 rounded-pill">{t("Our Projects")}</a></p>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div class="slider-item"  style={{ backgroundImage: `url(${dev2})`}}>
                <div class="overlay"></div>
                <Container>
                  <Row className="no-gutters slider-text align-items-center justify-content-start" data-scrollax-parent="true">
                    <Col md={7} >
                      <span class="subheading">{t("heretohelp")}</span>
                      <h1 class="mb-4">{t("Take your business to the next level")}</h1>
                      <p><a href="#services" class="btn btn-primary px-4 py-3 mt-3 rounded-pill">{t("Our Services")}</a></p>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Carousel.Item>
            <Carousel.Item>      
              <div class="slider-item"  style={{ backgroundImage: `url(${carous2})`}}>
                <div class="overlay"></div>
                <Container>
                  <Row className="no-gutters slider-text align-items-center justify-content-start" data-scrollax-parent="true">
                    <Col md={7} >
                      <span class="subheading">{t("Our value")}</span>
                      <h1 class="mb-4">{t("Excellence in Building Solutions")}</h1>
                      <p><a href="#values" class="btn btn-primary px-4 py-3 mt-3 rounded-pill">{t("Our Values")}</a></p>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Carousel.Item>
        </Carousel>
      </Container>
  );
}

export default TopCarousel;
