
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button';
import logoimg from "../images/logo.png"
import { AiFillMail } from "react-icons/ai";
import {FaMapMarkedAlt} from "react-icons/fa";
import {BsTelephoneOutboundFill}  from "react-icons/bs";
import * as Constants from '../constants'

import { useTranslation } from 'react-i18next';  


function Info() {
  const { t, i18n } = useTranslation();  
 
  return (
 
        
    <Container style={{padding:"15px"}} >
        <Row className="no-gutters d-flex align-items-center align-items-stretch header-style ">
          <Col className="align-items-center">
            <img src={logoimg} alt="Logo" width="170" style={{marginRight:"25px"}} />
          </Col>
          <Col lg={9} className="d-none d-sm-block"  xsHidden smHidden>
            <Row>
            {/* <Col sm={12} lg={4} className="align-items-center align-items-stretch" style={{margin:"auto"}}>
                <p class="text-start  fs-6  mb-0 font-weight-light mt-2" >
                  <a style={{color: "#1b9ce3",textDecoration:"none"}} href={"mailto:"+Constants.COMPANY_EMAIL}>
                    <AiFillMail  size={15}/> {Constants.COMPANY_EMAIL}
                  </a> 
                </p>
              </Col> */}
              <Col sm={12} lg={8} className="align-items-center align-items-stretch " style={{margin:"auto"}}>
                <p class=" fs-6  mb-0 font-weight-light mt-2" style={{textAlign:"center"}}>
                    
                  <a style={{color: "#1b9ce3",textDecoration:"none"}} href={Constants.MAP_LINK} target="_blank">
                    <FaMapMarkedAlt  size={15}/> {Constants.ADDRESS}
                  </a> 
                </p>
              </Col>
            
              {/* <Col className="align-items-center align-items-stretch" style={{margin:"auto"}}>      
              
                <p class="text-start  fs-6  mb-0 font-weight-light mt-2" >
                    
                    <a style={{color: "#1b9ce3",textDecoration:"none"}} href={"tel:"+Constants.PHONE_NUMBER} target="_blank">
                      <BsTelephoneOutboundFill  size={15}/> {Constants.PHONE_NUMBER}
                    </a> 
                  </p>
              </Col> */}
              <Col sm={12} lg={4} className="align-items-sm-center d-flex align-items-center justify-content-lg-end" style={{margin:"auto"}}>
              <Button className=" rounded-pill" variant="outline-theme" href="mailto:sales@platform.com" style={{width:"226px",height:"42px"}}>
              {t("Free Consulting")}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>       
  </Container>
      
  );
}

export default Info;
