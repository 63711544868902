
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import Container from 'react-bootstrap/Container'
import cloud from "../images/cloud.jpg"
import architecture from "../images/architecture.jpg"
import digital from "../images/digital.jpg"
import dev from "../images/dev1.jpg"
import finserv from "../images/finserv.jpg"
import ai from "../images/ai.jpg"
import training from "../images/organic-flat-people-business-training-illustration_23-2148901755.webp"
import skill from "../images/Skill-Development-Courses.jpg"

import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';  

function Projects() {
  const { t, i18n } = useTranslation(); 
  const modalContent = {
    cloud:{title:"Cloud Transformation",description:"We are experienced in AWS and we can build or move your entire solution to the cloud, we'll guide you through the entire process, let us know how we can help you"},
    arch:{title:"Enterprise Architecture",description:"We believe that architecture is an essential part of any delivery, It is not only about modeling and designs. For us, It's about building an entire process and framework tailored for you where you can build technology the right way."},
    transformation:{title:"Digital Transformation",description:"We have built digital solutions and strategies that can move your business to the next level without impacting your operations, no disruption, only innovation. We have done it for banks, fintechs, insurance and agriculture companies"},
    analytics:{title:"Data Analytics",description:"Our core belief is that data is the center of any success, know your data and you'll know your clients. However, we do understand that data management can be a problem and luckily we have the solution. Contact us to know more."},
    mlai:{title:"Machine Learning",description:"This is everywhere today and we absolutely believe in it being a part of our future, we also believe that you can predict the future of your business by building the right models around your data. Predict what's going to happen and mitigate those risks."},
    strategy:{title:"Training",description:"We live in a world of automation and technology, courses are everywhere on the web, but we still believe in the human element in training, we build tailored courses as per your needs and your skillset."},
    sfwd:{title:"Software Development",description:"We have experience building solutions in the different sectors, we have built websites and mobile apps for our clients in financial sectors, insurance, environment and agriculture and can any challenge and deliver high quality products. Some of our previous solutions include, mobile wallets, NFC payment, e-pin, card issuance ,merchant management systems, sustainability systems and more."},
    blockchain:{title:"Skill Building",description:"We want to be your partners and your advisors, we want to find your gaps and help you fill them. We can train your team, build it for you and advise on the technology that you need. Tell us your needs and we're there for you"},
  }
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  
  const handleTitle = (title) => setTitle(title);
  const handleDescription = (description) => setDescription(description);
  const handleClose = () => setShow(false);
  const handleShow = (key) => {
    handleTitle(modalContent[key].title);
    handleDescription(modalContent[key].description);
    setShow(true);
  }
  return (
<Container id="projects" fluid style={{paddingTop:"80px"}}>
  <Row className="no-gutters justify-content-center mb-5">
      <Col md={7} className="text-center heading-section">
        <h2 className="mb-4">{t("Our Projects")}</h2>
        <p>{t("project-brief")}</p>
        <p></p>
    </Col>
  </Row>
  <Row className="no-gutters">
    
  <Col md={3}>
      <div className="project img  d-flex justify-content-center align-items-center  " style={{ backgroundImage: `url(${cloud})`}}>
        <div className="overlay"></div>
        <div className="text text-center p-4">
          <h3><a href="#!"  onClick={() =>handleShow("cloud")}>{t("Cloud Transformation")}</a></h3>
          <span>{t("AWS CLOUD IMPLEMENTATION")}</span>
        </div>
      </div>
    </Col>
    <Col md={3}>
      <div className="project img  d-flex justify-content-center align-items-center  " style={{ backgroundImage: `url(${ai})`}}>
        <div className="overlay"></div>
        <div className="text text-center p-4">
          <h3><a href="#!"  onClick={() =>handleShow("mlai")}>{t("Machine Learning & AI")}</a></h3>
          <span>{t("PREDICT & AUTOMATE")}</span>
        </div>
      </div>
    </Col>
    <Col md={3}>
      <div className="project img  d-flex justify-content-center align-items-center  " style={{ backgroundImage: `url(${digital})`}}>
      <div className="overlay"></div>
      <div className="text text-center p-4">
      <h3><a href="#!"  onClick={() =>handleShow("transformation")}>{t("Digital Transformation")}</a></h3>
      <span>{t("DIGITIZE YOUR PROCESS & SOLUTIONS")}</span>
      </div>
      </div>
    </Col>
    <Col md={3}>
      <div className="project img  d-flex justify-content-center align-items-center  " style={{ backgroundImage: `url(${dev})`}}>
      <div className="overlay"></div>
      <div className="text text-center p-4">
      <h3><a href="#!"  onClick={() =>handleShow("sfwd")}>{t("Software Development")}</a></h3>
      <span>{t("WE CAN BUILD IT")}</span>
      </div>
      </div>
    </Col>
  </Row>
  <Row className=" no-gutters">
    <Col md={3}>
      <div className="project img  d-flex justify-content-center align-items-center  " style={{ backgroundImage: `url(${finserv})`}}>
      <div className="overlay"></div>
      <div className="text text-center p-4">
      <h3><a href="#!"  onClick={() =>handleShow("analytics")}>{t("Data Analytics")}</a></h3>
      <span>{t("YOUR DATA IS VALUABLE")}</span>
      </div>
      </div>
    </Col>
    <Col md={3}>
      <div className="project img  d-flex justify-content-center align-items-center  " style={{ backgroundImage: `url(${architecture})`}}>
        <div className="overlay"></div>
        <div className="text text-center p-4">
          <h3><a href="#!"  onClick={() =>handleShow("arch")}>{t("Enterprise Architecture")}</a></h3>
          <span>{t("Build it the right way")}</span>
        </div>
      </div>
    </Col>
    <Col md={3}>
      <div className="project img  d-flex justify-content-center align-items-center  " style={{ backgroundImage: `url(${skill})`}}>
      <div className="overlay"></div>
      <div className="text text-center p-4">
      <h3><a href="#!"  onClick={() =>handleShow("blockchain")}>{t("Skill Building")}</a></h3>
      <span>{t("We will build the team you need")}</span>
      </div>
      </div>
    </Col>
    <Col md={3}>
      <div className="project img  d-flex justify-content-center align-items-center  " style={{ backgroundImage: `url(${training})`}}>
      <div className="overlay"></div>
      <div className="text text-center p-4">
      <h3><a href="#!"  onClick={() =>handleShow("strategy")}>{t("Trainingtitle")}</a></h3>
      <span>{t("COURSES CUSTOMIZED FOR YOUR NEEDS")}</span>
      </div>
      </div>
    </Col>

  </Row>
  <Modal show={show} onHide={handleClose} size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
        <Modal.Header closeButton className="mod-head">
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
              <p style={{textAlign:"justify"}}>
                {description}
              </p>
        </Modal.Body>
        {/* <Modal.Footer>
          <a  role="button"  href="#contact" variant="primary" onClick={handleClose}>
            Contact Us
          </a>
        </Modal.Footer> */}
      </Modal>
</Container>
      
  );
}

export default Projects;
