import i18n from "i18next";   
import LanguageDetector from "i18next-browser-languagedetector";  
import { initReactI18next } from "react-i18next";
import en from "./locales/en/Language.json"
import fr from "./locales/fr/Language.json"
const resources = {
  en: en,
  fr: fr
};
i18n  
.use(initReactI18next)
  .use(LanguageDetector)  
  .init({  
    fallbackLng: "en", 
    lng:"en",
    debug: false,  
    react: {  
      bindI18n: "languageChanged",  
      bindI18nStore: "",  
      transEmptyNodeValue: "",  
      transSupportBasicHtmlNodes: true,  
      transKeepBasicHtmlNodesFor: ["br", "strong", "i"],  
      useSuspense: false  
    },
    resources
  });  
  
export default i18n;