
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'

import {BiMap}  from "react-icons/bi";
import {BsTelephone}  from "react-icons/bs";
import {MdOutlineEmail}  from "react-icons/md";
import {BsArrowRightShort,BsInstagram }  from "react-icons/bs";
import {FaFacebookF,FaLinkedinIn }  from "react-icons/fa";
import * as Constants from '../constants'
import { useTranslation } from 'react-i18next';  

function Contactus() {
  const { t, i18n } = useTranslation();  
  return (
    <footer id="contact" class="ftco-footer ftco-bg-dark ftco-section">
      <Container>
      <Row className="mb-5">
        <Col md={5} lg={5}>
          <div class="ftco-footer-widget mb-5">
            <h2 class="ftco-heading-2">{t("Have a Question")}</h2>
            <div class="block-23 mb-3">
              <ul>
                <li><a style={{color: "#1b9ce3",textDecoration:"none"}} href={Constants.MAP_LINK} target="_blank"><span class="icon"><BiMap size={25}/></span><span class="text">{Constants.ADDRESS}</span></a></li>
               <li><a href={"tel:"+Constants.PHONE_NUMBER}><span class="icon"><BsTelephone size={20}/></span><span class="text">{Constants.PHONE_NUMBER}</span></a></li> 
                <li><a href={"mailto:"+Constants.COMPANY_EMAIL}><span class="icon"><MdOutlineEmail size={20}/></span><span class="text">{Constants.COMPANY_EMAIL}</span></a></li>
                
              </ul>
            </div>
          </div>
        </Col>
        <Col md={5} lg={5}>
        <div class="ftco-footer-widget mb-5 ml-md-4">
          <h2 class="ftco-heading-2">{t("Links")}</h2>
          <ul class="list-unstyled footer-links">
            <li><a href="#home"><span class=" mr-2"><BsArrowRightShort size={20}/></span>{t("Home")}</a></li>
            <li><a href="#services"><span class=" mr-2"><BsArrowRightShort size={20}/></span>{t("Services")}</a></li>
            <li><a href="#values"><span class=" mr-2"><BsArrowRightShort size={20}/></span>{t("Values")}</a></li>
            <li><a href="#projects"><span class=" mr-2"><BsArrowRightShort size={20}/></span>{t("Projects")}</a></li>
            <li><a href="#contact"><span class=" mr-2"><BsArrowRightShort size={20}/></span>{t("Contact")}</a></li>
          </ul>
        </div>
        </Col>
        <Col md={2} lg={2}>
          <div class="ftco-footer-widget mb-5">
            {/* <h2 class="ftco-heading-2">{t("Connect With Us")} </h2> */}
            <ul class="ftco-footer-social list-unstyled float-md-left float-lft ">
              <li>
                <a title={t("followuson")+ " LinkedIn"} href={Constants.LINKEDIN_LINK} target="_blank">
                  <span><FaLinkedinIn size={20} /></span>
                </a>
              </li>
              {/* <li>
                <a title={t("followuson")+ " Instagram"} href={Constants.INSTAGRAM_LINK} target="_blank">
                 <span><BsInstagram  size={20} /></span>
                 </a>
              </li>
              <li >
                <a  title={t("followuson")+ " Facebook"}  href={Constants.FACEBOOK_LINK} target="_blank">
                 <span><FaFacebookF   size={20}/></span>
                  </a>
              </li> */}
            </ul>
          </div>
        </Col>
      </Row>
      <div class="row">
      <div class="col-md-12 text-center">
      <p>
      Copyright © {new Date().getFullYear()} Platform Hub Limited. All rights reserved.
      </p>
      </div>
      </div>
      </Container>
</footer>
      
  );
}

export default Contactus;
